<template>
  <div style="margin: 20px">
    <el-button style="margin-bottom: 10px" type="success" @click="create">Создать</el-button>
    <el-button v-loading="disableExportBtn" style="float: right" @click="downloadExport">Скачать журнал</el-button>
    <v-table v-loading="loading" :fetch="fetchData" name="pk" :columns="columns"/>
  </div>
</template>

<script>
import VTable from '@/components/VTable'
import { getPKIList } from '@/api/api'
import { getIogvDict } from '@/api/catalogs'
import { getExportJournal } from '@/api/file'
import { saveFile } from '@/utils'
export default {
  components: {
    VTable
  },
  data () {
    return {
      iogvs: [],
      loading: false,
      filters: null,
      disableExportBtn: false
    }
  },
  computed: {
    columns () {
      const classifications = [
        {
          id: 4,
          title: 'Контрольный показатель'
        }
      ]
      const iogvs = this.iogvs
      return [
        {
          field: 'valid',
          filter (value) {
            return value ? 'Да' : 'Нет'
          },
          width: '120px',
          items: [
            {
              id: 0,
              title: 'Нет'
            },
            {
              id: 1,
              title: 'Да'
            }
          ]
        },
        {
          field: 'code',
          width: '100px'
        },
        {
          field: 'pki_id',
          width: '100px'
        },
        {
          field: 'classification_id',
          filter (value) {
            return classifications.find(x => x.id === value)?.title
          },
          width: '135px',
          items: classifications,
          filterable: false
        },
        {
          field: 'ebosps',
          items: iogvs,
          multiple: true
        },
        {
          field: 'name',
          width: '300px'
        },
        {
          field: 'rule',
          type: 'formula',
          filterable: false,
          width: '200px'
        }
      ]
    }
  },
  mounted () {
    this.loading = true
    getIogvDict({ is_kp: true }).then((response) => {
      this.iogvs = response.data.data
      this.loading = false
    })
  },
  methods: {
    downloadExport () {
      this.disableExportBtn = true
      getExportJournal({ ...this.filters, classification_id: 4 }).then(response => {
        const fileName = response.headers['content-disposition'].split('filename*=utf-8\'\'')[1].split(';')[0]
        saveFile(response.data, decodeURI(fileName))
      }).finally(() => {
        this.disableExportBtn = false
      })
    },
    fetchData (params) {
      params.classification_id = 4
      this.filters = params
      return getPKIList(params)
    },
    create () {
      this.$router.push({ name: this.$route.name.replace('list', 'update') })
    }
  }
}
</script>

<style scoped>

</style>
